import * as React from 'react'

import { Checkbox } from './Checkbox'
import { DropdownFilter } from './DropdownFilter'
import { Input } from './Input'
import { Radio, RadioGroup } from './RadioGroup'

import {
  item,
  items,
  inputs,
  itemSelected,
  search as searchStyles
} from './ListFilter.module.css'

export function ListFilterDropdown (props) {
  const {
    filterKey,
    filters,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filterMultiple,
    onFilterChange
  } = props

  const filterOptions = props.filterOptions ?? props.filters

  const [search, setSearch] = React.useState('')
  const [newFilters, setNewFilters] = React.useState(filterOptions)

  React.useEffect(() => {
    if (search === '') {
      setNewFilters(filterOptions)
    } else {
      setNewFilters(
        filterOptions.filter(
          filter => filter.text.toLowerCase().indexOf(search.toLowerCase()) >= 0
        )
      )
    }
  }, [filterOptions, search])

  const handleFilter = ({ confirm, selectedKeys }) => {
    onFilterChange && onFilterChange(selectedKeys)
    filters[filterKey] = selectedKeys
    confirm()
  }

  const handleReset = () => {
    onFilterChange && onFilterChange([])
    clearFilters({ confirm: true })
    confirm()
    setSelectedKeys([])
    setSearch('')
  }

  const onChange = e => {
    const value = e?.target?.value
    if (filterMultiple) {
      const checked = e?.target?.checked
      const sanitizedKeys = Array.isArray(selectedKeys) ? selectedKeys : [selectedKeys]
      setSelectedKeys(
        (!isNaN(value) || value.length) && checked
          ? [...selectedKeys, value]
          : [...sanitizedKeys.filter(selected => selected !== value)]
      )
    } else {
      setSelectedKeys(value ? [value] : [])
    }
  }

  const isSelected = value => selectedKeys.includes(value)

  const renderRadioGroup = () => {
    const selectedValue = Array.isArray(selectedKeys) ? selectedKeys[0] : selectedKeys
    return (
      <RadioGroup onChange={onChange} value={selectedValue ?? ''}>
        <div className={items} data-testid="dropdown-filter-list">
          {newFilters.map(filter => (
            <label
              key={filter.value}
              className={isSelected(filter.value) ? itemSelected : item}
            >
              <Radio value={filter.value} checked={isSelected(filter.value)}>{filter.text}</Radio>
            </label>
          ))}
        </div>
      </RadioGroup>
    )
  }

  const renderCheckboxGroup = () => (
    <div
      className={items}
      data-testid="dropdown-filter-list"
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.stopPropagation()
          handleFilter({ selectedKeys, confirm, filterKey })
        }
      }}
    >
      {newFilters?.map(filter => (
        <label key={filter.value} className={item}>
          <Checkbox
            value={filter.value}
            onChange={onChange}
            checked={isSelected(filter.value)}
            data-testid={`check-${filter.value}`}
          >
            {filter.text}
          </Checkbox>
          <br />
        </label>
      ))}
    </div>
  )

  return (
    <DropdownFilter
      handleFilter={() => handleFilter({ selectedKeys, confirm, filterKey })}
      handleReset={() => handleReset()}
      padded={false}
    >
      <Input
        data-testid="select-status"
        placeholder="Search"
        onChange={e => setSearch(e?.target?.value ?? '')}
        className={searchStyles}
        value={search}
      />
      <div className={inputs}>
        {filterMultiple ? renderCheckboxGroup() : renderRadioGroup()}
      </div>
    </DropdownFilter>
  )
}
