import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const listJobApplications = async params => {
  try {
    const response = await api.get(`/job-applications`, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const updateJobApplication = async (jobApplicationId, values) => {
  try {
    const response = await api.put(
      `/job-applications/${jobApplicationId}`,
      values
    )
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const listJobApplicationsReviewers = async params => {
  try {
    const response = await api.get(`/job-applications/reviewers`, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const findAndUpdateJobApplication = async (params, values) => {
  try {
    const response = await api.get(`/job-applications`, { params })
    if (!response?.data?.length) {
      // No results: return null to inform the calling hook that no updates have occurred
      return null
    }
    const jobApplicationId = response.data[0].id

    const updateResponse = await api.put(
      `/job-applications/${jobApplicationId}`,
      values
    )
    return updateResponse
  } catch (e) {
    handleThrowError(e)
  }
}
