import React, { useState, useRef } from 'react'

import { listJobApplicationsReviewers } from '../../services/jobApplications'

let _fetchPromise

function fetch () {
  // Cache the promise
  if (!_fetchPromise) {
    _fetchPromise = listJobApplicationsReviewers()
  }

  return _fetchPromise
}

export default function useReviewers () {
  const didCancel = useRef(false)
  const [data, setData] = useState()

  React.useEffect(() => {
    const loadReviewers = async () => {
      const response = await fetch()

      if (!didCancel.current) {
        setData(response?.data)
      }
    }

    loadReviewers()

    return () => {
      didCancel.current = true
    }
  }, [])

  let reviewersById = null
  if (data) {
    reviewersById = {}
    data.forEach(user => {
      reviewersById[user.id] = user
    })
  }

  return {
    reviewersById
  }
}
