import React, { useState } from 'react'

import { Button } from '../../Button'
import { Tag } from '../../Tag'

import { getParsedText, sortByField } from '../../../utils/helpers'

import NoteFormDeprecated from './NoteFormDeprecated'

import { preview } from './Note.module.css'

function Note ({ values = {}, profileId, onEdit, onDelete, authData }) {
  const [editMode, setEditMode] = useState(false)

  const isAuthor = values.author === authData.id
  const { canUpdateAnyNote } = authData.role.capabilities || {}
  const isEligibleEditorUser = isAuthor || canUpdateAnyNote

  // So we don't accidentally render "Edit" button or show edit form
  const canEdit =
    isEligibleEditorUser &&
    typeof onEdit === 'function' &&
    typeof onDelete === 'function'

  if (editMode && canEdit) {
    return (
      <NoteFormDeprecated
        profileId={profileId}
        values={values}
        onEdit={onEdit}
        onDelete={onDelete}
        close={() => setEditMode(false)}
      />
    )
  }

  const parsedTextContent = getParsedText(values.body)

  return (
    <div>
      <div className={preview}>{parsedTextContent}</div>
      {(values.tags || []).sort(sortByField('slug')).map(({ id, slug }) => (
        <Tag key={id} color="green">
          {slug}
        </Tag>
      ))}

      {canEdit && (
        <div>
          <Button type="link" onClick={() => setEditMode(true)}>
            Edit
          </Button>
        </div>
      )}
    </div>
  )
}

export default Note
