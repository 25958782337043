import React from 'react'

export default function useDynamicTable ({ data = [] }) {
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([])

  const areAllExpanded =
    data.length > 0 && data.length === expandedRowKeys.length

  const toggleAll = e => {
    e.preventDefault()
    setExpandedRowKeys(areAllExpanded ? [] : data.map(({ id }) => id))
  }

  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record.id])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(id => id !== record.id))
    }
  }

  const buttonProps = {
    onClick: toggleAll,
    children: areAllExpanded ? <span data-testid="btn-collapse-table-records">Collapse All</span> : <span data-testid="btn-expand-table-records">Expand All</span>,
    disabled: data.length === 0
  }

  const tableProps = {
    expandedRowKeys,
    setExpandedRowKeys,
    onExpand
  }

  return {
    toggleAll,

    buttonProps,
    tableProps
  }
}
