import React, { useRef } from 'react'

import { FilterFilled } from '@ant-design/icons'
import { useLocation } from '@reach/router'
import moment from 'moment'
import queryString from 'query-string'

import { Button } from '../../Button'
import CavalryAvatar from '../../CavalryAvatar'
import { ListFilterDropdown } from '../../ListFilter'
import { Pagination } from '../../Pagination'
import { Table } from '../../Table'
import { Tag } from '../../Tag'
import TextFilterDropdown from '../../TextFilter'

import { notesSearch } from '../../../services/search'

import { useDynamicTable } from '../../../hooks/useDynamicTable'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { useNoteTags } from '../../../hooks/useNoteTags'
import { useReviewers } from '../../../hooks/useReviewers'
import { useTableExpandAll } from '../../../hooks/useTableExpandAll'

import {
  getSortOrder,
  updateQueryString,
  prepareSortItem
} from '../../../utils/filter-helpers'
import { compareStrings, sortByField } from '../../../utils/helpers'
import { trimEmptyParams } from '../../../utils/params'
import { createProfileNameColumn } from '../../../utils/profile-name-column'
import { FILTER_NAMES } from '../../../utils/search/filters/table/full-name'

import Note from './NoteDeprecated'

function ResultsTable ({ authData }) {
  const nameFilterInputRef = useRef(null)
  const { reviewersById } = useReviewers()
  const { tagsById } = useNoteTags()
  const { features } = useFeatureFlags()
  const defaultFilters = {
    noteTags: []
  }

  // Querystring
  const location = useLocation()
  const query = queryString.parse(location.search || '')

  const queryToFilter = {
    author: val => [val],
    fullName: val => val.split(',').filter(Boolean),
    noteTags: val => val.split(',').filter(Boolean),

    _start: val => val,
    _limit: val => val
  }

  Object.keys(query).forEach(key => {
    const val = query[key]
    const func = queryToFilter[key]
    if (!func) {
      return
    }

    const filterVal = func(val)

    if (typeof filterVal === 'object' && !Array.isArray(filterVal)) {
      Object.assign(defaultFilters, filterVal)
    } else {
      defaultFilters[key] = filterVal
    }
  })

  // By default, most recent notes go at the top
  const defaultSort = prepareSortItem(query._sort || 'noteCreatedAt:DESC')

  const {
    data,
    loading,
    pagination,
    setPagination,
    filters,
    sorter,
    onTableChange,
    onPaginationChange
  } = useDynamicTable({
    defaultFilters,
    defaultSort,

    filterToFetchParam: {
      fullName: (params, key, value) => {
        if (value) {
          params[key] = value.join(',')
        }
      },
      noteTags: (params, key, value) => {
        if (value) {
          params[key] = value.join(',')
        }
      }
    },

    fetch: async params => {
      const customParams = {
        _start: params._start,
        _limit: params._limit
      }

      Object.assign(params, trimEmptyParams(customParams))

      const response = await notesSearch({ params })

      const { total } = response.data
      setPagination(pagination => ({
        ...pagination,
        total
      }))

      // Mirror the search params in the querystring
      const queryParams = {
        fullName: params.fullName,
        author: params.author,
        noteTags: params.noteTags,

        _start: params._start,
        _limit: params._limit
      }

      // Also mirror sorter
      queryParams._sort = Array.isArray(params._sort)
        ? params._sort.join(',')
        : params._sort

      updateQueryString(queryParams)

      return response
    },

    defaultPagination: () => {
      const pageSize = defaultFilters._limit || 10
      const current =
        defaultFilters._start > 0 ? defaultFilters._start / pageSize + 1 : 1
      return {
        pageSize,
        current,
        total: null
      }
    },

    fetchedDataField: 'notes'
  })

  const expandAll = useTableExpandAll({ data: data.notes })

  const columns = [
    createProfileNameColumn({
      width: 250,
      sorter: (a, b) => compareStrings(a.fullName, b.fullName),
      filterIcon: isFiltered => <FilterFilled data-testid="name-filter-icon" className={isFiltered && 'active-state'}/>,
      filterDropdown: props => (
        <TextFilterDropdown
          {...props}
          ref={nameFilterInputRef}
          filters={filters}
          filterKey={FILTER_NAMES.optional}
        />
      ),
      onFilterDropdownVisibleChange: visible => {
        if (!visible) {
          return
        }

        const filterInputNode = nameFilterInputRef.current
        if (!filterInputNode) {
          return
        }

        setTimeout(() => {
          filterInputNode.select()
          filterInputNode.focus()
        }, 100)
      },
      filteredValue: filters.fullName ?? [],
      sortOrder: getSortOrder('fullName', sorter)
    }),
    {
      title: 'Author',
      key: 'author',
      dataIndex: 'authorEmail',
      width: 150,
      render: (value, { authorProfileURL: profileURL }) => (
        <CavalryAvatar email={value} profileURL={profileURL} features={features} />
      ),
      filterMultiple: false,
      filteredValue: filters.author ?? [],
      filterIcon: isFiltered => <FilterFilled data-testid="author-filter-icon" className={isFiltered && 'active-state'}/>,
      filterDropdown: props => (
        <ListFilterDropdown
          {...props}
          filterKey="author"
          filters={[
            {
              text: 'Me',
              value: authData.id
            }
          ].concat(
            reviewersById
              ? Object.values(reviewersById)
                .filter(user => user.id !== authData.id)
                .sort(sortByField('email'))
                .map(user => ({
                  text: user.email,
                  value: user.id
                }))
              : []
          )}
          filterMultiple={false}
        />
      )
    },
    {
      title: 'Tags',
      key: 'noteTags',
      dataIndex: 'matchedTags',
      render: value => (
        <>
          {(value || '').split(',').map(val => (
            <Tag key={val} color="green">
              {val}
            </Tag>
          ))}
        </>
      ),
      filterMultiple: true,
      filteredValue: filters.noteTags ?? [],
      filterIcon: isFiltered => <FilterFilled data-testid="tags-filter-icon" className={isFiltered && 'active-state'}/>,
      filterDropdown: props => (
        <ListFilterDropdown
          {...props}
          filterKey="noteTags"
          filters={
            tagsById
              ? Object.keys(tagsById)
                .map(id => ({
                  text: tagsById[id].slug,
                  value: id
                }))
                .sort(sortByField('text'))
              : []
          }
          filterMultiple={true}
        />
      )
    },
    {
      title: 'Body',
      key: 'body',
      dataIndex: 'body',
      render: value => value && value.substr(0, 100),
      ellipsis: true
    },
    {
      title: 'Creation Date',
      dataIndex: 'noteCreatedAt',
      key: 'noteCreatedAt',
      render: value => moment(value).format('YYYY-MM-DD HH:mm'),
      sorter: true,
      sortOrder: getSortOrder('noteCreatedAt', sorter)
    }
  ].filter(Boolean)

  // Guess the width that we'll need to account for.
  const minTableWidth = columns.reduce(
    (acc, col) => acc + (col.width || 300),
    0
  )

  return (
    <>
      <Pagination onChange={onPaginationChange} {...pagination} />
      <Button style={{ marginBottom: '1rem' }} {...expandAll.buttonProps} />

      <Table
        columns={columns}
        size="small"
        scroll={{ x: minTableWidth }}
        rowKey="id"
        dataSource={data.notes}
        loading={loading}
        pagination={false}
        onChange={onTableChange}
        expandRowByClick
        expandable={{
          expandedRowRender: record => (
            <Note
              profileId={record.profile}
              values={record}
              authData={authData}
            />
          )
        }}
        {...expandAll.tableProps}
      />

      <Pagination onChange={onPaginationChange} {...pagination} />
    </>
  )
}

function NotesScreen ({ authData }) {
  return <ResultsTable authData={authData} />
}

export default NotesScreen
