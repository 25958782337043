import { useEffect, useState } from 'react'

import { useIsMounted } from '../useIsMounted'

import { CancelToken, isCancel } from '../../services/strapi/api'

import { API_ERROR_MESSAGES } from '../../utils/constants'
import { trimEmptyParams } from '../../utils/params'

function standardFilterToFetchParam (params, key, value) {
  params[key] = value
}

function prepareSortItem ({ columnKey, order }) {
  if (!columnKey || !order) {
    return null
  }

  const dir = order === 'ascend' ? 'ASC' : 'DESC'
  return `${columnKey}:${dir}`
}

function prepareSort (sorter) {
  // Normalize as array.
  if (!Array.isArray(sorter)) {
    return prepareSortItem(sorter)
  }

  return sorter.map(prepareSortItem).filter(Boolean)
}

// Used to cancel a request (ref: https://github.com/axios/axios#cancellation)
let cancelToken

export default function useDynamicTable ({
  fetchedDataField = 'results', // In the data object returned by the endpoint
  defaultFilters = {},
  defaultSort = {},
  defaultPagination = {
    pageSize: 10,
    current: 1,
    total: null
  },
  filterToFetchParam = {},
  fetchCount = undefined,
  fetch = undefined,
  lazy = false
}) {
  const isMounted = useIsMounted()
  const [pagination, setPagination] = useState(defaultPagination)
  const [filters, setFilters] = useState(defaultFilters)
  const [sorter, setSorter] = useState(defaultSort)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchPage = async ({ pagination, filters, sorter }) => {
    if (!fetch) {
      return
    }

    setLoading(true)

    setFilters(state => ({
      ...state,
      ...filters
    }))

    setSorter(sorter)

    const { current, pageSize } = pagination

    const fetchParams = {}

    // Convert filters to params.
    Object.keys(filters).forEach(key => {
      const value = filters[key]
      const func = filterToFetchParam[key] || standardFilterToFetchParam

      func(fetchParams, key, value)
    })

    trimEmptyParams(fetchParams)

    if (fetchCount) {
      const countRes = await fetchCount({
        ...fetchParams
      })

      if (isMounted()) {
        setPagination({
          ...pagination,
          total: countRes.data
        })
      }
    } else {
      setPagination(pagination)
    }

    // Check if there are any previous pending requests
    if (typeof cancelToken !== 'undefined') {
      cancelToken.cancel(API_ERROR_MESSAGES.canceledOperation)
    }

    // Save the cancel token for the current request
    cancelToken = CancelToken.source()

    try {
      const res = await fetch(
        {
          ...fetchParams,

          _start: (current - 1) * pageSize,
          _limit: pageSize,
          _sort: prepareSort(sorter)
        },
        { pagination, filters, sorter },
        cancelToken
      )

      // We're past first page with empty result set
      const isInvalidPaginationState =
        current > 1 &&
        Array.isArray(res.data[fetchedDataField]) &&
        res.data[fetchedDataField].length === 0

      if (isInvalidPaginationState) {
        onTableChange({ ...pagination, current: 1, total: res.data?.total || 0 }, filters, sorter)
        return
      }

      if (isMounted()) {
        setData(res.data)
        setLoading(false)
      }
    } catch (error) {
      // If the operation was canceled to start a new one
      // continue loading until the new request is complete
      if (isCancel(error)) {
        return setLoading(true)
      }
      throw error
    }
  }

  const onTableChange = (newPagination, newFilters, sorter) => {
    fetchPage({
      pagination: { ...pagination, ...newPagination },
      filters: { ...filters, ...newFilters },
      sorter
    })
  }

  const onPaginationChange = (current, pageSize) => {
    const newPagination = { ...pagination, pageSize, current }
    setPagination(newPagination)
    onTableChange(newPagination, filters, sorter)
  }

  // Fetch data once on mount
  useEffect(() => {
    if (!lazy) {
      fetchPage({ pagination, filters, sorter })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    pagination,
    setPagination,
    filters,
    setFilters: (filters, updatedSorter) => {
      if (lazy) {
        setFilters(state => ({
          ...state,
          ...filters
        }))
      } else {
        onTableChange(
          { ...pagination, current: 1 },
          filters,
          updatedSorter || sorter
        )
      }
    },
    sorter,
    setSorter,
    data,
    setData,
    loading,
    setLoading,
    onTableChange,
    onPaginationChange,
    fetchPage
  }
}
