import { navigate } from 'gatsby'
import queryString from 'query-string'

export function getSortOrder (key, sorter) {
  if (!sorter) {
    return false
  }

  const sorterArray = Array.isArray(sorter) ? sorter : [sorter]

  for (const { columnKey, order } of sorterArray) {
    if (key === columnKey) {
      return order
    }
  }

  return false
}

export function updateQueryString (values) {
  if (!window.history.pushState || !window.history.replaceState) {
    return
  }

  const search = `?${queryString.stringify(removeEmpties(values))}`
  const pathName = window.location.pathname
  const newLocation = `${pathName}${search}`
  navigate(newLocation, {
    replace: true,
    state: values,
  })
}

export function mapStringArrayToNumberArray (value) {
  return Array.isArray(value) ? value.map(key => Number(key)) : [Number(value)]
}

function removeEmpties (obj) {
  Object.keys(obj).forEach(key => {
    const val = obj[key]
    if (val === null || val === '' || typeof val === 'undefined') {
      delete obj[key]
    }
  })

  return obj
}

export function prepareSortItem (sortQueryParamValue) {
  return sortQueryParamValue
    .split(',')
    .filter(Boolean)
    .map(item => {
      const [columnKey, dir = 'ASC'] = item.split(':')
      const order = dir.toUpperCase() === 'ASC' ? 'ascend' : 'descend'
      return {
        columnKey,
        order
      }
    })
}

// SortQueryParamValue comes in the form of:
//  - `columnName:ASC`
//  - `column1Name:ASC,column2Name:DESC`
export function applySorter ({
  sortQueryParamValue = '',
  setSorter,
  query = {}
}) {
  const sortedInfo = prepareSortItem(sortQueryParamValue)

  // Set sorting order on table columns
  setSorter(sortedInfo)

  // Update the sorter in the querystring
  updateQueryString({ ...query, _sort: sortQueryParamValue })
}

export function normalizeMultipleFilterValue (value) {
  if (value) {
    if (Array.isArray(value)) {
      return value
    }
    return [value]
  }

  return value
}

export const filterColumnRenderer = (renderer, isActiveFilter) => (
  filters,
  contextParams
) => {
  if (!isActiveFilter(filters)) {
    return
  }

  return renderer(filters, contextParams)
}
