// Hook: useEntity
//
// CRUD actions for Strapi entities

import React from 'react'

import { request as api } from '../../services/strapi/api'

function useEntity (type, id) {
  const [state, setState] = React.useState({
    isSaving: false,
    isDeleting: false,
    error: null
  })

  const startSaving = React.useCallback(() => {
    setState(state => ({
      ...state,
      isSaving: true,
      error: null
    }))
  }, [])

  const handleError = React.useCallback(error => {
    console.error(error)
    setState(state => ({
      ...state,
      isSaving: false,
      isDeleting: false,
      error: error.message
    }))
  }, [])

  const handleData = React.useCallback(data => {
    setState({
      isSaving: false,
      data
    })

    return data
  }, [])

  const create = React.useCallback(
    values => {
      startSaving()

      return api
        .post(`/${type}`, values)
        .then(res => handleData(res.data))
        .catch(handleError)
    },
    [type, startSaving, handleData, handleError]
  )

  const save = React.useCallback(
    values => {
      startSaving()

      return api
        .put(`/${type}/${id}`, values)
        .then(res => handleData(res.data))
        .catch(handleError)
    },
    [type, id, startSaving, handleData, handleError]
  )

  const destroy = React.useCallback(() => {
    setState(state => ({
      ...state,
      isDeleting: true,
      error: null
    }))

    return api
      .delete(`/${type}/${id}`)
      .then(() => {
        setState({
          isDeleting: false
        })
      })
      .catch(handleError)
  }, [type, id, handleError])

  return {
    ...state,
    isBusy: state.isSaving || state.isDeleting,

    create,
    save,
    destroy
  }
}

export default useEntity
