import React, { useEffect } from 'react'

import { DropdownFilter } from './DropdownFilter'
import { Input } from './Input'

import { input } from './TextFilter.module.css'

const TextFilterDropdown = props => {
  const {
    filterKey,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filters,
    placeholderText
  } = props

  const filterInputEl = props.forwardedRef

  // Initial filter pre-selection and auto-focus. Happens once.
  useEffect(() => {
    if (!filterInputEl) {
      return
    }

    setTimeout(() => {
      const el = filterInputEl.current
      if (el.select) {
        el.select()
      } else if (el.focus) {
        el.focus()
      }
    }, 100)
  }, [filterInputEl])

  const handleSearch = (selectedKeys, confirm) => {
    confirm()
  }

  const handleReset = clearFilters => {
    filters[filterKey] = null
    clearFilters()
  }

  const onChange = event => {
    const value = event.target ? event.target.value : event
    setSelectedKeys(value ? [value] : [])
  }

  const filteredValue = selectedKeys || ''
  return (
    <DropdownFilter
      handleFilter={() => handleSearch(selectedKeys, confirm, filterKey)}
      handleReset={() => handleReset(clearFilters)}
    >
      <Input
        data-testid={filterKey}
        ref={filterInputEl}
        className={input}
        placeholder={placeholderText || 'Filter by Name or Email'}
        value={filteredValue}
        onChange={onChange}
        onPressEnter={e => {
          e.stopPropagation()
          e.preventDefault()
          filters[filterKey] = selectedKeys
          handleSearch(selectedKeys, confirm, filterKey)
        }}
      />
    </DropdownFilter>
  )
}

export default React.forwardRef((props, ref) => (
  <TextFilterDropdown {...props} forwardedRef={ref} />
))
