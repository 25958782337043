import React from 'react'

import { ProfileNameColumn } from '../components/ProfileNameColumn'
import { name as nameStyles } from '../components/SearchScreen/SearchScreen.module.css'

import filterSpec from './search/filters/table/full-name'

export function createProfileNameColumn (attr = {}) {
  const { filteredValue } = attr

  return {
    title: <span data-testid="name-email-filter-title">{filterSpec.title}</span>,
    key: 'fullName',

    render: profile => (
      <ProfileNameColumn profile={profile} filteredValue={filteredValue} />
    ),
    onCell: () => ({
      className: nameStyles
    }),

    ...attr
  }
}
