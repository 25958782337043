import React from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import { Button } from '../../Button'
import { Form, FormItem } from '../../Form'
import { Input } from '../../Input'
import { message } from '../../Message'
import { Select } from '../../Select'

import { App } from 'redesign/components/App'

import { useEntity } from '../../../hooks/useEntity'
import { useForm } from '../../../hooks/useForm'
import { useNoteTags } from '../../../hooks/useNoteTags'

import { entityTypes, sortByField } from '../../../utils/helpers'

const NUMBER_REGEX = /^[0-9]+$/

function NoteForm ({
  values = {},
  profileId,
  onCreate,
  onEdit,
  onDelete,
  close
}) {
  const { modal: { confirm } } = App.useApp()

  const { id } = values
  const { tagsById } = useNoteTags()

  const [form] = useForm()

  const { save, create, destroy, isBusy, isSaving, isDeleting } = useEntity(
    entityTypes.notes,
    values.id
  )

  const origTags = values.tags || []
  const onFinish = async values => {
    values.id = id

    // For now we are going to remove non-numeric tags.
    // TODO: non-numeric tags are new tags we need to create.
    values.tags = values.tags
      .filter(tag => NUMBER_REGEX.test(tag))
      .map(tag => parseInt(tag, 10))

    // Find tags that are no longer present and negate them,
    // so that they will be removed.
    for (const tag of origTags) {
      if (!values.tags.includes(tag.id)) {
        values.tags.push(tag.id * -1)
      }
    }

    try {
      if (values.id) {
        const data = await save(values)
        if (!data) {
          message.error('Updating Note failed')
          return
        }

        onEdit(values.id, {
          ...data,
          author: data.author.id
        })

        message.success('Saved Note')
      } else {
        values.profile = profileId
        const data = await create(values)
        if (!data) {
          message.error('Creating Note failed')
          return
        }

        onCreate({
          ...data,
          author: data.author.id
        })
        form.resetFields()
        message.success('Created Note')
      }
    } catch (error) {
      message.error(error)
    }

    if (close) {
      close()
    }
  }

  const onClickDelete = event => {
    event.preventDefault()

    confirm({
      title: 'Permanently delete item? You can\'t undo this.',
      icon: <ExclamationCircleOutlined />,
      okText: 'DELETE',
      cancelText: 'CANCEL',
      async onOk () {
        await destroy()
        onDelete(values.id)

        message.info('Deleted Note')
      }
    })
  }

  const onClickCancel = () => {
    confirm({
      title: 'Discard unsaved changes?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DISCARD',
      cancelText: 'CANCEL',
      onOk () {
        form.resetFields()
        if (close) {
          close()
        }
      }
    })
  }

  const tagOptions =
    tagsById &&
    Object.keys(tagsById)
      .map(id => ({
        label: tagsById[id].slug,
        value: id
      }))
      .sort(sortByField('label'))

  return (
    <Form
      initialValues={{
        ...values,
        tags: (values.tags || []).map(item => String(item.id))
      }}
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <FormItem name="body" label="Body" rules={[{ required: true }]}>
        <Input.TextArea style={{ minHeight: '4rem' }} autoSize />
      </FormItem>

      {tagOptions && (
        <FormItem name="tags" label="Tags">
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            tokenSeparators={[',']}
            options={tagOptions}
            optionFilterProp="label"
          />
        </FormItem>
      )}

      <FormItem>
        <Button
          type="primary"
          htmlType="submit"
          loading={isSaving}
          disabled={isBusy}
        >
          Save
        </Button>{' '}
        {onDelete && (
          <Button
            danger
            onClick={onClickDelete}
            loading={isDeleting}
            disabled={isBusy}
          >
            Delete
          </Button>
        )}{' '}
        <Button type="link" onClick={onClickCancel} disabled={isBusy}>
          Cancel
        </Button>
      </FormItem>
    </Form>
  )
}

export default NoteForm
