function normalizeParam (value) {
  if (Array.isArray(value)) {
    return value.filter(v => typeof v === 'number' || Boolean(v))
  }

  if (value === '' || value === 'null') {
    return null
  }

  return value
}

// Trim params that have no effect on the request.
export function trimEmptyParams (params) {
  Object.keys(params).forEach(key => {
    const value = (params[key] = normalizeParam(params[key]))

    if (Array.isArray(value) && !value.length) {
      delete params[key]
    }

    if (value === null) {
      delete params[key]
    }
  })

  return params
}
