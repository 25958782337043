import React from 'react'

import { Pagination as PaginationComponent } from 'antd'
import clsx from 'clsx'

import { pagination } from './Pagination.module.css'

const Pagination = ({ onChange, pageSize, current, total, showSizeChanger = true, totalResultsText = 'results', className = '' }) => {
  if (!total) {
    return null
  }

  return (
    <div className={clsx(pagination, className)}>
      <PaginationComponent
        onChange={onChange}
        onShowSizeChange={onChange}
        showSizeChanger={showSizeChanger}
        showTotal={(total, range) =>
          total
            ? `${range[0]}-${range[1]} of ${total} ${totalResultsText}`
            : `Loading ${totalResultsText}...`
        }
        pageSize={pageSize}
        current={current}
        total={total}
        size="small"
      />
    </div>
  )
}

export default Pagination
