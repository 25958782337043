// Specify once for easier change
export const FILTER_NAMES = {
  regular: 'nameOrEmail',
  optional: 'fullName'
}

const spec = {
  title: 'Name / Email',

  defaultValues: {
    [FILTER_NAMES.regular]: null,
    [FILTER_NAMES.optional]: null
  },

  isActive (filters) {
    return Boolean(filters[FILTER_NAMES.regular])
  },

  queryString: {
    paramsSerializer (params) {
      return {
        [FILTER_NAMES.regular]: params[FILTER_NAMES.regular]
      }
    },

    populateFilterDefaultValues () {
      return {
        [FILTER_NAMES.regular]: val => val.split(',').filter(Boolean)
      }
    }
  },

  request: {
    paramsSerializer () {
      return {
        [FILTER_NAMES.regular]: (params, key, value) => {
          if (value) {
            params[key] = value.join(',')
          }
        }
      }
    },

    customParamsSerializer (filters) {
      // Initially the table filter stores in the key fullName so storing it in
      // nameOrEmail key
      if (filters[FILTER_NAMES.optional]) {
        filters[FILTER_NAMES.regular] = filters[FILTER_NAMES.optional]
      }
      return {
        [FILTER_NAMES.regular]: filters[FILTER_NAMES.optional]
          ? filters[FILTER_NAMES.optional].join(',').trimStart()
          : null
      }
    },

    transformParams (params) {
      delete params[FILTER_NAMES.optional]
    }
  }
}

export default spec
