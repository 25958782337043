import React from 'react'

import { AuthConsumer } from '../providers/AuthProvider'

import Layout from '../components/Layout'
import { NotesScreen } from '../components/Profile/Notes'
import SEO from '../components/SEO'

const IndexPage = () => (
  <Layout menuSelectedKeys={['notes']}>
    <SEO title="Notes" />

    <AuthConsumer>
      {({ isLoading, data }) => {
        if (isLoading) {
          return <div>Loading...</div>
        }

        if (!data) {
          return <div>Not logged in</div>
        }

        return (
          <div className="content">
            <NotesScreen authData={data} />
          </div>
        )
      }}
    </AuthConsumer>
  </Layout>
)

export default IndexPage
