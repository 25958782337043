import {
  handleThrowError,
  normalizeConfig
} from '../utils/services'

import {
  request as api,
  getCacheBuster
} from './strapi/api'

export const listNoteTags = async (config = {}) => {
  try {
    normalizeConfig(config)
    config.params._cb = getCacheBuster('note-tag')

    const response = await api.get('/note-tags', config)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
