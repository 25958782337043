import React from 'react'

import { listNoteTags } from '../../services/noteTags'

let _fetchPromise

function fetch () {
  const params = {
    _limit: 999
  }

  // Cache the promise
  if (!_fetchPromise) {
    _fetchPromise = listNoteTags({ params })
  }

  return _fetchPromise
}

export default function useNoteTags () {
  const [data, setData] = React.useState()

  React.useEffect(() => {
    const loadNoteTags = async () => {
      const { data } = await fetch()
      setData(data)
    }

    loadNoteTags()
  }, [])

  let tagsById = null
  if (data) {
    tagsById = {}
    data.forEach(tag => {
      tagsById[tag.id] = tag
    })
  }

  return {
    tagsById
  }
}
